<template>
   <div id="inside">
    <div class="container">
      <div
          class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
              <button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-user-o"></i>cadastro</button
              ><button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-key"></i>código de acesso</button
              >
          </div>
        </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Boletos</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <button class="btn btn-primary mt-3" @click="voltar()">Voltar</button>
        </div>
      </div>
    </div>

    <!-- cetelem -->

    <div class="container" style="margin-bottom: 100px;">
      <div class="row">
        <div class="col-lg-12" v-if="boletoSelected.contractNumber">
          <div class="card" v-for="(value, key, index) in boletoSelected"
            :key="`${key}-${index}`">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5 class="">{{ value.Produto }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h6 class="text-primary mt-3 mb-0">Contrato {{ value.contractNumber.substring(0, 3) + 'XXXXXX' +  value.contractNumber.substr(-3) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row border-bottom mt-4 pb-4" v-for="(installments, key2, index) in value.installments"
            :key="`${key2}-${index}`">
                <div class="col-xs-8 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Pagamento:</p>
                  <h6 class="d-inline-flex bg-primary text-light rounded small py-2 px-2">{{ value.installments.length > 1 ? 
                  String(key2 + 1) + "/" + String(value.installments.length) : "À vista" }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Valor:</p>
                  <!-- <h6 class="">{{ installments.installmentsValue }}</h6> -->
                  <h6 class=""> 51.6</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <!-- <h6 class="small"> {{ installments.dueDate }} </h6> -->
                  <h6 class="small"> {{ formatData(dataVencimento[key2], "YYYY-MM-DD", "DD/MM/YYYY") }} </h6>
                </div>
                <div v-bind:class="[ largura < 500 ? 'col-12 col-lg-12' : 'col-6 col-lg-5' ]" >
                  <p class="small mb-1 d-inline-flex">Pagamento:</p>
                  <p class="small d-inline-flex mb-1 ml-2 mr-3 text-info">{{ "EM ABERTO" }}</p>
                </div>
                <div class="col-xs-8 col-12 col-lg-6" style="">
                  <p class="text-info mt-3 mb-1">Linha digitável:</p>
                  <a class="btn btn-link btn-sm mt-0 float-right" 
                  href="#"
                  @click="copiarLinhaDigitavel('23793.13022 50831.414599 05006.305204 8 70010000002000')"
                  >Copiar</a>
                  <p class="codebar">{{ '23793.13022 50831.414599 05006.305204 8 70010000002000' }}</p>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-if="!loading">
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" v-if="!loading" @click="generateBoleto(value, installments, key2)"> Baixar boleto</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" v-if="user.PhoneNumber || user.MobileNumber" @click="sendSms(installments, '23793.13022 50831.414599 05006.305204 8 70010000002000')">&nbsp;Receber por SMS</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="sendEmail(installments, '23793.13022 50831.414599 05006.305204 8 70010000002000')">&nbsp;Receber por e-mail</a>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-else-if="loading">
                  <ProgressLoading v-bind:loading="loading" />

                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-else>
                  <button type="button" class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="generateBoleto(value, installments, key2)"> Gerar Boleto</button>
                </div>
                <div class="col-xs-3 col-3 d-flex flex-column col-lg-1 pb-3 pt-2" style="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="">
      </div>
    </div>


    <!-- one -->



    <div class="container" style="margin-bottom: 100px;">
      <div class="row">
        <div class="col-lg-12" v-if="boletoSelected.Provider">
          <div class="card" v-for="(value, key, index) in [boletoSelected]"
            :key="`${key}-${index}`">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5 class="">{{ value.Produto }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h6 class="text-primary mt-3 mb-0">Contrato {{ value.NumeroTitulo.substring(0, 3) + 'XXXXXX' +  value.NumeroTitulo.substr(-3) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row border-bottom mt-4 pb-4" v-for="(installments, key2, index) in value.ParcelasAcordo.ParcelaAcordo"
            :key="`${key2}-${index}`">
                <div class="col-xs-8 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Pagamento:</p>
                  <h6 class="d-inline-flex bg-primary text-light rounded small py-2 px-2">{{ value.ParcelasAcordo.ParcelaAcordo.length > 1 ? 
                  String(key2 + 1) + "/" + String(value.ParcelasAcordo.ParcelaAcordo.length) : "À vista" }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Valor:</p>
                  <h6 class="">{{ parseFloat(installments.ValorParcela.replace(',','.'), 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <h6 class="small"> {{ installments.DataVencimento }} </h6>
                </div>
                <div v-bind:class="[ largura < 500 ? 'col-12 col-lg-12' : 'col-6 col-lg-5' ]" >
                  <p class="small mb-1 d-inline-flex">Pagamento:</p>
                  <p class="small d-inline-flex mb-1 ml-2 mr-3 text-info">{{ installments.StatusParcelaAcordo }}</p>
                </div>
                <div class="col-xs-8 col-12 col-lg-6" style="" v-if="installments.LinhaDigitavel && installments.StatusParcelaAcordo !== 'BAIXADO'" >
                  <p class="text-info mt-3 mb-1">Linha digitável:</p>
                  <a class="btn btn-link btn-sm mt-0 float-right" 
                  href="#"
                  @click="copiarLinhaDigitavel(installments.LinhaDigitavel)"
                  >Copiar</a>
                  <p class="codebar">{{ installments.LinhaDigitavel }}</p>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-if="installments.LinhaDigitavel && installments.StatusParcelaAcordo !== 'BAIXADO' && !loading">
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" v-if="!loading" @click="generateBoleto(value, installments, key2)"> Baixar boleto</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" v-if="user.PhoneNumber || user.MobileNumber" @click="sendSms(installments, installments.LinhaDigitavel)">&nbsp;Receber por SMS</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="sendEmail(installments, installments.LinhaDigitavel)">&nbsp;Receber por e-mail</a>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-else-if="loading && installments.StatusParcelaAcordo === 'EM ABERTO' && !installments.firstBillIsPaymentPending">
                  <ProgressLoading v-bind:loading="loading" />

                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-else>
                  <button type="button" :disabled="installments.firstBillIsPaymentPending || installments.StatusParcelaAcordo === 'BAIXADO'" class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="generateBoleto(value, installments, key2)"> Gerar Boleto</button>
                </div>
                <div class="col-xs-3 col-3 d-flex flex-column col-lg-1 pb-3 pt-2" style="">
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="">
                  <p v-if="installments.firstBillIsPaymentPending" class="small d-inline-flex mb-1 text-info">Aguardando pagamento da parcela anterior.</p>
                  <p v-if="installments.StatusParcelaAcordo === 'BAIXADO'" class="small d-inline-flex mb-1 text-info">Boleto pago.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12" v-if="boletoSelected.contract">
        <div v-for="(value, key, index) in boletoSelected.contract.agreementsOne"
            :key="`${key}-${index}`">
        <div class="col-lg-12" v-if="value.contrato === boletoSelected.contrato">
          <div class="card">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h6 class="text-primary mt-3 mb-0">Contrato ONE-{{ value.contrato.substring(0, 3) + 'XXXXXX' +  value.contrato.substr(-3) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row border-bottom mt-4 pb-4">
                <div class="col-xs-8 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Valor:</p>
                  <h6 class="">{{ parseFloat(value.valor.replace(',','.'), 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <h6 class="small"> {{ formatData(value.vencimento, "YYYY-MM-DD", "DD/MM/YYYY")}} </h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                </div>
                
                <div v-bind:class="[ largura < 500 ? 'col-12 col-lg-12' : 'col-6 col-lg-5' ]" >
                </div>
                <div class="col-xs-8 col-12 col-lg-6" style="" v-if="value.linhadigitavel" >
                  <p class="text-info mt-3 mb-1">Linha digitável:</p>
                  <a class="btn btn-link btn-sm mt-0 float-right" 
                  href="#"
                  v-if="value.linhadigitavel !== 'Linha digitável indisponível'"
                  @click="copiarLinhaDigitavel(value.linhadigitavel)"
                  >Copiar</a>
                  <p class="codebar">{{ value.linhadigitavel }}</p>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-if="!loading">
                  <p class="text-info mt-3 mb-4"></p>
                  <a class="btn mr-1 mb-1 btn-outline-primary" :disabled="loading" href="#" v-if="value.linkdoboleto !== 'Link indisponível' && !loading" @click="openBoletoOne(value, value.linkdoboleto)"> Baixar boleto</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" :disabled="loading" href="#" v-if="value.linhadigitavel !== 'Linha digitável indisponível' && !loading" @click="sendSms(value, value.linhadigitavel)">&nbsp;Receber por SMS</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" :disabled="loading" href="#" v-if="value.linhadigitavel !== 'Linha digitável indisponível' && !loading" @click="sendEmail(value, value.linhadigitavel)">&nbsp;Receber por e-mail</a>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="" v-else-if="loading && installment === value">
                  <ProgressLoading v-bind:loading="loading" />
                </div>
                <div class="col-xs-3 col-3 d-flex flex-column col-lg-1 pb-3 pt-2" style="">
                </div>
              </div>
              <div class="row border-bottom mt-4 pb-4">
              <div class="col-xs-8 col-12 col-lg-6" style="" v-if="value.pixcode != 'Código PIX indisponível'" >
                  <p class="text-info mt-3 mb-1">Código PIX:</p>
                  <a class="btn btn-link btn-sm mt-0 float-right" 
                  href="#"
                  v-if="value.pixcode != 'Código PIX indisponível'"
                  @click="copiarCodigoPix(value.pixcode)"
                  >Copiar</a>
                  <p class="codebar">{{ value.pixcode }}</p>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="align-items: center;" v-if="!loading && value.pixcode !== 'Código PIX indisponível'">
                  <hr>
                  <template >
                    <qrcode-vue :value="value.pixcode" :size="150" level="H" foreground="#4D2978" />
                  </template>
                  <p class="text-info mt-3 mb-4">Aponte seu celular</p>
                </div>
                
            </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
      <div class="row" style="">
      </div>
    </div>
    <modal name="newEmail" height="auto" style="margin: auto;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="eModalLabel">Você não tem nenhum e-mail cadastrado.</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="fecharModal()">
            <span aria-hidden="true">x</span>
            </button>
        </div>

        <label class="m-4">Por favor, informe um novo e-mail.</label>
        <div class="col-12">
                              <input 
                                v-model="newEmail"
                                :disabled="loading"
                                maxlength="50"
                                type="text" class="form-control my-3" placeholder="E-mail">
                          </div>
                                <!-- :required="!form.phone && !form.mobileNumber ? true : false" -->
       
        <div class="modal-footer">
              <button
                v-if="!loading"
                class="btn btn-secondary text-white"
                type="button"
                data-dismiss="modal"
                @click="fecharModal()"
              >
                Cancelar
              </button>
              <a v-if="!loading" class="btn btn-primary" href="#" @click="insertNewEmailAndSend()"
                >Cadastrar</a
              >
              <ProgressLoading v-bind:loading="loading" />
            </div>
        </div>
        
    </modal>
    <modal name="newPhoneNumber" height="auto" style="margin: auto;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="eModalLabel">Você não tem nenhum telefone cadastrado.</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="fecharModalNewPhone()">
            <span aria-hidden="true">x</span>
            </button>
        </div>

        <label class="m-4">Por favor, informe um novo número de telefone.</label>
        <div class="col-12">

          <input
                type="tel"
                class="
                  form-control form-control-lg
                  text-center
                  home-form
                  quicks
                  mb-2
                "
                placeholder="Informe o telefone aqui"
                id="form10"
                v-model="newPhoneNumber"
                v-mask="['(##) # ####-####']"
              />
                          </div>
                                <!-- :required="!form.phone && !form.mobileNumber ? true : false" -->
       
        <div class="modal-footer">
              <button
                v-if="!loading"
                class="btn btn-secondary text-white"
                type="button"
                data-dismiss="modal"
                @click="fecharModalNewPhone()"
              >
                Cancelar
              </button>
              <a v-if="!loading" class="btn btn-primary" href="#" @click="insertNewPhoneNumberAndSend()"
                >Cadastrar</a
              >
              <ProgressLoading v-bind:loading="loading" />
            </div>
        </div>
        
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import { mask } from "vue-the-mask";
import QrcodeVue from 'qrcode.vue'

export default {
    name: "HistoricoPagamentos",
    template: '<qrcode-vue :value="value"></qrcode-vue>',
     components: {
      ProgressLoading,
      QrcodeVue
    },
    directives: { mask },
    data () {
        return {
            negociacao: null,
            loading: false,
            selectDeal:null,
            digitalLine:null,
            checkSelectAll: false,
            checkContract: false,
            links: [],
            contracts: {},
            agreements: [],
            boletoSelected: {},
            dataVencimento: [],
            productContractClosed: null,
            newEmail: null,
            newPhoneNumber: null,
            linhaDigitavel: null,
            installment: {},
            ownerId: 0,
            info: {},
            userAdm: {},
            ipUser: null
        }
    },
    methods: {
        formatValue,
        formatData,
        ...mapActions("meusBoletos", [
          "ActionSendEmailOrSmsText"
        ]),

        ...mapActions("meusContratos", ["ActionDownloadBill"]),

        fecharModal(){
          this.$modal.hide('newEmail');
        },
        abrirModal(){
          this.$modal.show('newEmail');
        },
        fecharModalNewPhone(){
          this.$modal.hide('newPhoneNumber');
        },
        abrirModalNewPhone(){
          this.$modal.show('newPhoneNumber');
        },

        deactivated() {
            this.divida = null;
            this.negociacao = null; 
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
          this.$router.push({ 
            name: 'meusBoletos',
          });
        },

        async generateBoleto(agreementData, installment, key2) {
          let formattedAgreementData = agreementData
          formattedAgreementData.ParcelasAcordo.ParcelaAcordo = agreementData.ParcelasAcordo.ParcelaAcordo.map((value) => {
            return { ...value, Base64: "" }
          });

          try {
            this.loading = true;
            if(!formattedAgreementData.Produto) {
              formattedAgreementData.Produto = formattedAgreementData.NumeroTitulo.split('.')[1];
              formattedAgreementData.NumeroTitulo = formattedAgreementData.NumeroTitulo.split('.')[0];
            } 

            const jsonBoleto = {
              agreementData: formattedAgreementData,
              installmentAgreementCode: installment.CodigoParcelaAcordo,
              agreementCode: agreementData.CodigoAcordo,
              key: agreementData.Provider,
              info: this.info,
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            }
              
            const responseFromBoleto = await this.ActionDownloadBill(jsonBoleto)
            this.boletoSelected.ParcelasAcordo.ParcelaAcordo[key2]['DataVencimento'] = responseFromBoleto.ParcelasAcordo.ParcelaAcordo[0]['DataVencimento']
            this.boletoSelected.ParcelasAcordo.ParcelaAcordo[key2]['ValorParcela'] = responseFromBoleto.ParcelasAcordo.ParcelaAcordo[0]['ValorParcela']
            this.boletoSelected.ParcelasAcordo.ParcelaAcordo[key2]['LinhaDigitavel'] = responseFromBoleto.ParcelasAcordo.ParcelaAcordo[0]['LinhaDigitavel']
            this.boletoSelected.ParcelasAcordo.ParcelaAcordo[key2]['Base64'] = responseFromBoleto.ParcelasAcordo.ParcelaAcordo[0]['Base64']
            localStorage.setItem("boletoSelected", JSON.stringify(this.boletoSelected));

            
            const linkSource = `data:application/pdf;base64,${responseFromBoleto.ParcelasAcordo.ParcelaAcordo[0]['Base64']}`;
            const downloadLink = document.createElement("a");
            downloadLink.setAttribute('target', '_blank');
            const fileName = `boleto-claro.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            this.flashMessage.show({  
              timeout:1000,
              blockClass: 'custom-block-class',
              status: 'success', 
              title: 'Sucesso', 
              message: 'Download do boleto efetuado com sucesso.'});
            this.loading = false;
          } catch {
            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Error",
              message: "Não foi possível realizar o download do boleto",
            });
            this.loading = false;
          }
        },

        async downloadBoleto(value) {
          this.loading = true;
          try {
            const linkSource = `data:application/pdf;base64,${value['Base64']}`;
            const downloadLink = document.createElement("a");
            downloadLink.setAttribute('target', '_blank');
            const fileName = `boleto-claro.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            // await this.insertLog(200);

            this.flashMessage.show({
              timeout:1000,
              blockClass: 'custom-block-class',
              status: 'success', 
              title: 'Sucesso', 
              message: 'Download do boleto efetuado com sucesso.'});
            this.loading = false;

          } catch (error) {
            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Error",
              message: error.message,
            });
            this.loading = false;
          }
        },

        openBoletoOne(installment, linkBoleto) {
          this.installment = installment;
          window.open(linkBoleto, '_blank');
        },

        compareDates(date) {
          let parts = date.split("/");
          let today = new Date();

          date = new Date(parts[2], parts[1] - 1, parts[0]);

          return today > date ? true : false;
        },

        async sendSms(installment, LinhaDigitavel) {
          this.installment = installment;
          this.loading = true;
            if(this.user.PhoneNumber === '' || this.user.PhoneNumber === null &&  this.user.MobileNumber === '' || this.user.MobileNumber === null) {
              this.loading = false;
              this.linhaDigitavel = LinhaDigitavel;
              this.abrirModalNewPhone();
              return;
            }
          try {
            await this.ActionSendEmailOrSmsText({
              LinhaDigitavel,
              Email: '',
              PhoneNumber: this.user.PhoneNumber != null ? this.user.PhoneNumber : this.user.MobileNumber,
              type: 'sms',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            });

            this.flashMessage.show({
              timeout:1000,
              blockClass: 'custom-block-class',
              status: 'success', 
              title: 'Sucesso', 
              message: 'Linha digitável enviado por sms com sucesso'});
            this.loading = false;
          } catch {
            this.loading = false;
          }
        },

        validateEmail(email) {
          var re = /\S+@\S+\.\S+/;
          return re.test(email);
        },

        async insertNewEmailAndSend() {
          this.loading = true;


          if(this.newEmail === '' || this.newEmail === null) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Informe um e-mail!",
              });
              this.loading = false;
            return;
          }

          var email = this.validateEmail(this.newEmail);
          if (!email) {
            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Ocorreu um erro",
              message: "Informe um email válido!",
            });
            this.loading = false;
            return;
          }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.linhaDigitavel,
              Email: this.newEmail,
              PhoneNumber: '',
              type: 'email',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            });


            if(resp.data.code !== 200) {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por e-mail",
              });
              this.loading = false;
              return;
            } else {
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success', 
                title: 'Sucesso', 
                message: 'Linha digitável enviado por e-mail com sucesso'});
                this.loading = false;
                this.fecharModal();
              }
          } catch {
            this.loading = false;
          }
        },

        async insertNewPhoneNumberAndSend() {
          this.loading = true;
          
          if(this.newPhoneNumber === '' || this.newPhoneNumber === null) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Informe um telefone!",
              });
              this.loading = false;
            return;
          }

          // var phone = this.validatePhone(this.newPhoneNumber);
          // if (!phone) {
          //   this.flashMessage.show({
          //     timeout: 1000,
          //     status: "error",
          //     title: "Ocorreu um erro",
          //     message: "Informe um telefone válido!",
          //   });
          //   this.loading = false;
          //   return;
          // }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.linhaDigitavel,
              Email: '',
              PhoneNumber: this.newPhoneNumber.replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace("-", ""),
              type: 'sms',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            });


            if(resp.data.code !== 200) {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por sms",
              });
              this.loading = false;
              return;
            } else {
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success', 
                title: 'Sucesso', 
                message: 'Linha digitável enviado por sms com sucesso'});
                this.loading = false;
                this.fecharModalNewPhone();
              }
          } catch {
            this.loading = false;
          }
        },

        async sendEmail(installment, LinhaDigitavel) {
          this.installment = installment;
          this.loading = true;

          if(this.user.Email === '' || this.user.Email === null) {
            this.loading = false;
            this.linhaDigitavel = LinhaDigitavel;
            this.abrirModal();
            return;
          }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel,
              Email: this.user.Email,
              PhoneNumber: '',
              type: 'email',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            });

            if(resp.data.code !== 200) {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por e-mail",
              });
              this.loading = false;
              return;
            } else {
              this.loading = false;
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success', 
                title: 'Sucesso', 
                message: 'Linha digitável enviado por e-mail com sucesso'});
                
              }
              this.loading = false;

          } catch {
            this.loading = false;
          }
        },

        async copiarLinhaDigitavel(linhaDigitavel) {
          const elem = document.createElement("textarea");
          elem.value = linhaDigitavel;
          document.body.appendChild(elem);
          elem.select();
          document.execCommand("copy");
          document.body.removeChild(elem);

          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            blockClass: "custom-block-class",
            message: "Linha digitável foi copiada para o seu clipboard",
          });
        },

        async copiarCodigoPix(pixcode) {
          const elem = document.createElement("textarea");
          elem.value = pixcode;
          document.body.appendChild(elem);
          elem.select();
          document.execCommand("copy");
          document.body.removeChild(elem);

          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            blockClass: "custom-block-class",
            message: "Código PIX foi copiado para o seu clipboard",
          });
        },
        
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        },

        selectAll() {
              var marcar = document.getElementById('all').checked;
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = marcar;
              }
        },

        select() {
          var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
          var all = document.getElementById('all');
          if(lista.length <= 2) {
            all.checked = false;
          }
        },

        clear() {
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = false;
              }
        },

        acordoFechado() {
          this.$router.push({ 
            name: 'resumoAcordo',
            params: { contracts: this.contractFull },
          });
        },

        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
            params: { agreements: this.contracts.agreements, contracts: this.contracts }
          });
        },

        contractsPending() {
          this.$router.push({
            name: "meusContratos"
          });
        },

        cadastro() {
          this.$router.push({
            name: "cadastro",
            params: { agreements: this.contracts.agreements, contracts: this.contracts }
          });
        },
    },
    async created() {
        this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
        this.ipUser = localStorage.getItem('ipUser');
        this.largura = window.screen.width;
        this.loading = true;

        if (!localStorage.getItem("contracts") || localStorage.getItem("contracts") === null || 
        typeof localStorage.getItem("contracts") === "undefined" || localStorage.getItem("contracts") === "undefined") {
          this.contracts = [];
        } else {
          this.contracts = JSON.parse(localStorage.getItem("contracts"));
        }
        
        this.boletoSelected = JSON.parse(localStorage.getItem("boletoSelected"))
        this.ownerId = JSON.parse(localStorage.getItem("OwnerID"))
        this.info = JSON.parse(localStorage.getItem("info"))

        this.dataVencimento = [
        '2024-01-09','2024-02-09','2024-03-09','2024-04-09','2024-05-09','2024-06-09','2024-07-09','2024-08-09',
        '2024-09-09','2024-10-09','2024-11-09','2024-12-09','2025-01-09','2025-02-09','2025-03-09','2025-04-09',
        '2025-05-09','2025-06-09','2025-07-09','2025-08-09','2025-09-09','2025-10-09','2025-11-09','2025-12-09','2026-01-09','2026-02-09'
        ]
        this.loading = false;
    },
    computed: {
      ...mapState("auth", ["user"], ['contacts']),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>
